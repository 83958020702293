import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Box } from '@components/Box';
import { Text, View } from 'react-native';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "box"
    }}>{`Box`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Box} mdxType="Props" />
    <h2 {...{
      "id": "box-with-light-theme"
    }}>{`Box with `}<em parentName="h2">{`light`}</em>{` theme`}</h2>
    <Playground __position={1} __code={'<Box title=\"SUGGESTED FANS\" theme=\"light\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Box,
      Text,
      View,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box title="SUGGESTED FANS" theme="light" mdxType="Box" />
    </Playground>
    <h2 {...{
      "id": "box-with-dark-theme"
    }}>{`Box with `}<em parentName="h2">{`dark`}</em>{` theme`}</h2>
    <Playground __position={2} __code={'<Box title=\"PROFILE COMPLETED 10%\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Box,
      Text,
      View,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box title="PROFILE COMPLETED 10%" mdxType="Box" />
    </Playground>
    <h2 {...{
      "id": "box-with-action"
    }}>{`Box with action`}</h2>
    <Playground __position={3} __code={'<Box\n  title=\"PROFILE COMPLETED 10%\"\n  onPressTitle=\"see all\"\n  onPress={() => {\n    console.log(\'press action\')\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Box,
      Text,
      View,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box title="PROFILE COMPLETED 10%" onPressTitle="see all" onPress={() => {
        console.log('press action');
      }} mdxType="Box" />
    </Playground>
    <h2 {...{
      "id": "box-with-children"
    }}>{`Box with children`}</h2>
    <Playground __position={4} __code={'<Box\n  title=\"PROFILE COMPLETED 10%\"\n  onPressTitle=\"see all\"\n  onPress={() => {\n    console.log(\'press action\')\n  }}\n>\n  <View>\n    <Text>Children render inside the box</Text>\n  </View>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Box,
      Text,
      View,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box title="PROFILE COMPLETED 10%" onPressTitle="see all" onPress={() => {
        console.log('press action');
      }} mdxType="Box">
  <View mdxType="View"><Text mdxType="Text">Children render inside the box</Text></View>
  </Box>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      